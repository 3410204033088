import { fonts } from '../fonts'

export const buttonFontSize = 16
export const buttonIconFontSizeMedium = 20

const Button = {
	MuiButton: {
		styleOverrides: {
			root: {
				fontFamily: fonts.montserrat,
				fontSize: buttonFontSize,
				borderRadius: 0,
			},
			sizeMedium: {
				'& .MuiButton-iconSizeMedium.MuiButton-startIcon': {
					'& .MuiSvgIcon-fontSizeMedium': {
						fontSize: buttonIconFontSizeMedium,
					},
				},
			},
			containedSecondary: {
				color: '#000000',
				backgroundColor: '#ffffff',
				minHeight: 60,
				'&:hover': {
					boxShadow: 'none',
					backgroundColor: '#ffffff',
				},
				'&:focus': {
					boxShadow: 'none',
					backgroundColor: '#ffffff',
				},
				'&:active': {
					boxShadow: 'none',
					backgroundColor: '#ffffff',
				},
				'&.Mui-disabled': {
					color: '#000000',
					backgroundColor: '#ffffff',
				},
				'& .MuiButton-startIcon': {
					fill: '#000000',
				},
				'& >svg': {
					color: '#000000',
				},
				'& .MuiButton-endIcon': {
					fill: '#000000',
				},
			},
			textLink: {
				textDecoration: 'underline',
				padding: 0,
			},
		},
	},
}

export default Button
