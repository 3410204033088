import Bag2Icon from '@bluheadless/ui/src/atoms/icons/bag-2-icon'

const BHTileBase = {
	BHTileBase: {
		defaultProps: {
			wishListProps: {
				position: 'inner',
				variant: 'icon',
				iconProps: { color: 'primary' },
			},
			addToCartProps: {
				position: 'inner',
				variant: 'icon',
				Icon: Bag2Icon,
				iconProps: { color: 'primary' },
			},
		},
	},
}
export default BHTileBase
