const BHUpsells = {
	BHUpsells: {
		defaultProps: {
			showTitle: true,
			sliderProps: {
				breakpoints: {
					xs: {
						spaceBetween: 6,
					},
					md: {
						spaceBetween: 6,
					},
				},
				controlsPosition: 'default',
				spaceBetween: 6,
				pagination: false,
			},
		},
	},
}

export default BHUpsells
