const Dialog = {
	MuiDialog: {
		styleOverrides: {
			paperWidthLg: {
				maxWidth: '880px',
				padding: '70px',
				'& .iconClose': {
					right: '22px',
					top: '22px',
				},
			},
		},
	},
}

export default Dialog
