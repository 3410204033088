import defaultTheme from '@bluheadless/ui/src/theme/default'
import { createTheme as createMuiTheme } from '@mui/material/styles' // keep @mui/material/styles to use default theme of @mui/material
import { createTheme } from '@mui/system'
import BHBreadcrumbs from './theme/components/bh-breadcrumbs'
import BHCrossSell from './theme/components/bh-crosssell'
import BHRecentlyViewed from './theme/components/bh-recently-viewed'
import BHRelated from './theme/components/bh-related'
import BHRelatedAttributes from './theme/components/bh-related-attributes'
import BHTileBase from './theme/components/bh-tile-base'
import BHUpsells from './theme/components/bh-upsells'
import Button from './theme/components/button'
import Container from './theme/components/container'
import CssBaseline from './theme/components/css-baseline'
import Dialog from './theme/components/dialog'
import Tab from './theme/components/tab'
import { fonts } from './theme/fonts'

const htmlFontSize = 14
const fontSize = 14

const muiTheme = createMuiTheme({ typography: { htmlFontSize, fontSize } })

const fontFamily = fonts.montserrat.fontFamily
const fontFamilyAlternative = fonts.prata.fontFamily

export const theme = createTheme(defaultTheme, {
	palette: {
		common: {
			black: '#000000',
			white: '#FFFFFF',
		},
		primary: {
			main: '#000000',
		},
		black: {
			main: '#000000',
		},
		white: {
			main: '#FFFFFF',
		},
		grey: {
			main: '#777777',
			lighter: '#F7F7F7',
		},
		footer: {
			bgTop: '#FFFFFF',
			bgMiddleTop: '#000000',
			bgMiddle: '#000000',
			bgMiddleBottom: '#000000',
			bgBottom: '#000000',
			border: '#f3f3f3',
			color: '#FFFFFF',
		},
		background: {
			paper: '#FFFFFF',
			default: '#FFFFFF',
			header: '#000000',
			card: '#F6F6F6',
		},
		tile: {
			background: '#F6F6F6',
		},
	},
	typography: {
		color: '#000000',
		fontFamily,
		fontFamilyAlternative,
		htmlFontSize,
		fontSize,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		h1: {
			...fonts.prata,
			fontSize: muiTheme.typography.pxToRem(30),
			lineHeight: 1.35,
			letterSpacing: '0.2px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(40),
				// lineHeight: 1.167,
			},
		},
		h2: {
			...fonts.prataBold,
			fontSize: muiTheme.typography.pxToRem(22),
			lineHeight: 1.185,
			letterSpacing: '0.2px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(32),
			},
		},
		h3: {
			...fonts.prataBold,
			fontSize: muiTheme.typography.pxToRem(20),
			lineHeight: 1.167,
			letterSpacing: '0.2px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(28),
			},
		},
		h4: {
			...fonts.montserratBold,
			fontSize: muiTheme.typography.pxToRem(18),
			lineHeight: 1.235,
			letterSpacing: '0.2px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(24),
			},
		},
		h5: {
			...fonts.montserratBold,
			fontSize: muiTheme.typography.pxToRem(16),
			lineHeight: 1.2,
			letterSpacing: '0.2px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(20),
			},
		},
		h6: {
			...fonts.montserratBold,
			fontWeight: 800,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: '23px',
			letterSpacing: '0.2px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: muiTheme.typography.pxToRem(18),
			},
		},
		body1: {
			...fonts.montserrat,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.8,
			letterSpacing: '0',
		},
		body2: {
			...fonts.montserrat,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.4,
			letterSpacing: '0.32px',
		},
		subtitle1: {
			...fonts.montserrat,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.8,
			letterSpacing: '0.2px',
		},
		subheadline2: {
			...fonts.montserrat,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.8,
			letterSpacing: '0.2px',
		},
		button: {
			...fonts.montserratBold,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.3,
			letterSpacing: '0.2px',
		},
		caption: {
			...fonts.montserrat,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: 1.4,
			letterSpacing: '0.2px',
		},
		overline: {
			...fonts.montserrat,
			fontSize: muiTheme.typography.pxToRem(12),
			lineHeight: 2.66,
			letterSpacing: '0.2px',
			textTransform: 'uppercase',
		},
		card: {
			...fonts.prata,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: 1.35,
			letterSpacing: '0.2px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.sm}px)`]: {
				fontSize: muiTheme.typography.pxToRem(24),
			},
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(40),
			},
		},
		attributetitle: {
			...fonts.montserratBold,
			fontSize: muiTheme.typography.pxToRem(16),
			letterSpacing: '0.32px',
		},
		attributetitle2: {
			...fonts.montserratBold,
			fontSize: muiTheme.typography.pxToRem(14),
			letterSpacing: '0.32px',
		},
		attribute: {
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: '19px',
			letterSpacing: '0.32px',
			...fonts.montserratMedium,
		},
	},
	shape: {
		borderRadius: 0,
	},
	components: {
		...BHBreadcrumbs,
		...BHCrossSell,
		...BHRecentlyViewed,
		...BHRelated,
		...BHRelatedAttributes,
		...BHTileBase,
		...BHUpsells,
		...Button,
		...Container,
		...CssBaseline,
		...Dialog,
		...Tab,
	},
})

export default theme
