const BHBreadcrumbs = {
	BHBreadcrumbs: {
		defaultProps: {
			separator: '>',
		},
	},
	MuiBreadcrumbs: {
		styleOverrides: {
			separator: {
				margin: '0px 10px',
			},
			li: {
				display: 'flex',
			},
		},
	},
}
export default BHBreadcrumbs
