const BHRelated = {
	BHRelated: {
		defaultProps: {
			sliderProps: {
				controlsPosition: 'default',
				breakpoints: {
					xs: { spaceBetween: 6 },
					md: {
						spaceBetween: 6,
					},
				},
				spaceBetween: 6,
				pagination: false,
			},
		},
	},
}

export default BHRelated
