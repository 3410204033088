export const fontSize = 14
export const htmlFontSize = 14
export const fonts = {
	montserratLight: {
		fontFamily: 'Montserrat, sans-serif',
		fontWeight: 300,
	},
	montserrat: {
		fontFamily: 'Montserrat, sans-serif',
		fontWeight: 400,
	},
	montserratMedium: {
		fontFamily: 'Montserrat, sans-serif',
		fontWeight: 500,
	},
	montserratBold: {
		fontFamily: 'Montserrat, sans-serif',
		fontWeight: 700,
	},
	prataLight: {
		fontFamily: 'Prata, serif',
		fontWeight: 300,
	},
	prata: {
		fontFamily: 'Prata, serif',
		fontWeight: 400,
	},
	prataMedium: {
		fontFamily: 'Prata, serif',
		fontWeight: 500,
	},
	prataBold: {
		fontFamily: 'Prata, serif',
		fontWeight: 700,
	},
}
