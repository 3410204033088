import { fonts } from '../fonts'
import defaultTheme from '@bluheadless/ui/src/theme/default'

const Tab = {
	MuiTabs: {
		styleOverrides: {
			root: {
				minHeight: 'auto',
				paddingBottom: 30,
				[defaultTheme.breakpoints.up('md')]: {
					paddingBottom: 38,
				},
			},
			indicator: {
				display: 'none',
			},
		},
	},
	MuiTab: {
		styleOverrides: {
			root: {
				...fonts.montserratMedium,
				background: 'none',
				fontSize: 18,
				lineHeight: 1.222,
				minHeight: 'auto',
				minWidth: 'auto',
				paddingTop: 0,
				paddingBottom: 0,
				'&:hover': {
					backgroundColor: null,
				},
				'&.Mui-selected': {
					...fonts.montserratBold,
				},
				[defaultTheme.breakpoints.up('md')]: {
					paddingLeft: 70,
					paddingRight: 70,
				},
			},
		},
	},
}

export default Tab
